import React from 'react';
import { string } from 'prop-types';

const Guidelines = ({ from }) => {
  const FROM = from;

  return (
    <div className="overlay-page-content">
      <p>
        For product {FROM}s (&quot;{FROM.charAt(0).toUpperCase() + FROM.slice(1)}s&quot;), please focus on the
        product and your invidual experience with it:
      </p>
      <ul>
        <li>
          For Shipping / Damaged / Delivery item issue, click on Feedback Tab, click on Website
          Feedback, click on Feedback topic, click on Shipping/Delivery, add your Feedback, or
          contact us at 1-800-430-3376, do not post on product review
        </li>
        <li>What features stand out most?</li>
        <li>What do you like or dislike about it?</li>
        <li>How long have you had it?</li>
        <li>Does it meet your expectations?</li>
        <li>
          Do not include any personal information about yourself or others, such as social security
          numbers, credit or debit card numbers, payment card data, financial account numbers,
          account passwords, or government issued identification information.
        </li>
        <li>
          Avoid promoting or sharing opinions or beliefs about political issues, or religious
          affiliations.
        </li>
      </ul>
    </div>
  );
};

Guidelines.propTypes = {
  from: string
};

Guidelines.defaultProps = {
  from: ''
};

export { Guidelines };
