import React from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { usePresentation } from '../../../../context/PresentationProvider';

export const ExistingUser = ({ displayName, email, onNotMeClick }) => {
  const { useCondensedLayout } = usePresentation();
  const existingUser = classNames({
    'write-review-content__no-padding': useCondensedLayout,
    'write-review-content__existing-user': true
  });
  const existingUserNotMe = classNames({
    'write-review-content__no-padding write-review-content__existing-user__not-me__mobile': useCondensedLayout,
    'write-review-content__existing-user__not-me': !useCondensedLayout
  });
  const existingUserNotMeMobile = classNames({
    'write-review-content__existing-user__not-me__email-mobile': useCondensedLayout
  });
  const existingUserDisplayNameClass = classNames({
    'write-review-content__existing-user__display__name-mobile': useCondensedLayout,
    'write-review-content__existing-user__display__name': !useCondensedLayout
  });
  const existingUserDisplayValue = classNames({
    'write-review-content__existing-user__display__value-mobile': useCondensedLayout,
    'write-review-content__existing-user__display__value': !useCondensedLayout
  });
  return (
    <>
      <Row
        className={existingUser}
      >
        <Col
          xs="5"
          className={existingUserNotMe}
        >
          <span
            className={existingUserNotMeMobile}
          >
            You are contributing as {email}
          </span>
          <button
            className="write-review-content__existing-user__button"
            onClick={onNotMeClick}
            type="button"
          >
            Not me
          </button>
        </Col>
      </Row>
      <div className="write-review-content__existing-user__display">
        <div
          className={existingUserDisplayNameClass}
        >
          Display Name
        </div>
        <div
          className={existingUserDisplayValue}
        >
          {displayName}
        </div>
      </div>
    </>
  );
};

ExistingUser.displayName = 'ExistingUser';

ExistingUser.propTypes = {
  displayName: string.isRequired,
  email: string.isRequired,
  onNotMeClick: func.isRequired
};
