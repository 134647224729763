import React from 'react';

const Terms = () => {
  return (
    <div className="overlay-page-content terms">
      <p>
        These Terms and Conditions (“T&amp;C”) govern your submission of Content on websites
        maintained by The Home Depot, Inc. and its subsidiaries, divisions, and affiliates
        (collectively, &ldquo;Home Depot&rdquo;). Your submission of Content constitutes your
        agreement and acceptance of these T&amp;C. Please read the following carefully and
        completely. If you do not agree and decline to accept these T&amp;C, do not submit Content.
        From time to time, Home Depot may change these T&amp;C by posting an updated copy. Your
        submission of Content is also governed by the homedepot.com Terms of Use, found at{' '}
        <a href="https://www.homedepot.com/c/Terms_of_Use" target="_blank" rel="noreferrer">
          https://www.homedepot.com/c/Terms_of_Use
        </a>{' '}
        (the “Terms of Use”), which are incorporated by reference herein.{' '}
      </p>
      <p>
        By submitting Content, you represent and warrant that you your Content complies with the
        requirements in the “Uploaded Content” section of the Terms of Use. You agree to indemnify
        and hold Home Depot (and its officers, directors, agents, subsidiaries, joint ventures,
        employees and third-party service providers, including but not limited to, Bazaarvoice,
        Inc.), harmless from all claims, demands, and damages (actual and consequential) of every
        kind and nature, known and unknown including reasonable attorneys&apos; fees, arising out of
        a breach of your representations and warranties set forth above, or your violation of any
        law or the rights of a third party.{' '}
      </p>
    </div>
  );
};

export { Terms };
