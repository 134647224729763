import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { Button } from '@thd-olt-component-react/button';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { usePresentation } from '../../../../context/PresentationProvider';
import { OverlayPage } from '../../../OverlayPage/OverlayPage';
import { Terms } from './Terms';
import { Guidelines } from './Guidelines';

export const FormFooter = ({ className, onSubmit }) => {
  const { useCondensedLayout } = usePresentation();

  const links = [
    {
      label: 'Terms and Conditions',
      title: 'Terms and Conditions',
      page: <Terms />
    },
    {
      label: 'Review Guidelines',
      title: 'Product Review Writing Guidelines',
      page: <Guidelines from="review" />
    }
  ];

  const [overlayPageOpen, setOverlayPageOpen] = useState(false);
  const [overlayPageTitle, setOverlayPageTitle] = useState('');
  const [overlayPageContent, setOverlayPageContent] = useState(null);

  const openOverlayPage = (title, content) => {
    setOverlayPageTitle(title);
    setOverlayPageContent(content);
    setOverlayPageOpen(true);
  };

  const closeOverlayPage = () => {
    setOverlayPageOpen(false);
    setOverlayPageContent(null);
  };

  return (
    <Row className={className}>
      <Col xs="10">
        <div className="write-review-content__footer__links">
          {links.map((footer) => (
            <span
              key={footer.label}
              onClick={() => openOverlayPage(footer.title, footer.page)}
              role="link"
              tabIndex={0}
            >
              {footer.label}
            </span>
          ))}
        </div>
      </Col>
      <Col xs="2" className="write-review-content__submit">
        <Button onClick={onSubmit}>Submit</Button>
      </Col>
      <OverlayPage
        overlayPageTitle={overlayPageTitle}
        OverlayPageContent={overlayPageContent}
        overlayPageOpen={overlayPageOpen}
        closeOverlayPage={closeOverlayPage}
        innerOverlay
      />
    </Row>
  );
};

FormFooter.displayName = 'RatingsAndReviewsFormFooter';

FormFooter.propTypes = {
  className: string,
  onSubmit: func.isRequired
};

FormFooter.defaultProps = {
  className: null
};
