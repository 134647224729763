import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import {
  arrayOf, func, shape, string
} from 'prop-types';
// TODO: Use withAxios
import axios from 'axios';
import { Col, Row, Image } from '@thd-olt-component-react/core-ui';
import { Input } from '@thd-olt-component-react/input';

import { ExperienceContext } from '~/@thd-nucleus/experience-context';
import { usePresentation } from '../../../../context/PresentationProvider';
import '../../WriteAReview.styles.scss';

export const ImageUpload = ({
  className, images, setImages
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const { hosts } = useContext(ExperienceContext);
  const { useCondensedLayout } = usePresentation();
  // TODO: Use key provider when available
  const prodHost = 'https://api.bazaarvoice.com';
  const { bazaarVoice = prodHost } = hosts || {};
  const passkey = bazaarVoice === prodHost
    ? 'b763vhiq4ca8pmty8eiin091r'
    : 'tsbz566hffu0g5lk0rmwr4mdk';
  const uploadUrl = `${bazaarVoice}/data/uploadphoto.json?apiversion=5.4&Contenttype=review&passkey=${passkey}`;

  const removeImage = (imageIndex) => {
    const newImages = [...images];
    newImages.splice(imageIndex, 1);
    setImages(newImages);
  };

  const updateImageCaption = ({ caption, imageIndex }) => {
    const updated = [...images];
    updated[imageIndex].caption = caption;
    setImages(updated);
  };

  const columnClasses = classNames('write-review-content__add-photo__border', className, {
    'write-review-content__add-photo__wrapper': !useCondensedLayout,
  });
  const rowClasses = classNames('write-review-content__add-photo', className);

  const handleFileChange = (event) => {
    setUploading(true);
    const formData = new FormData();
    formData.append('photo', event.target.files[0]);
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    axios.post(uploadUrl, formData, { headers })
      .then((response) => {
        const { data } = response || {};
        const { FormErrors, Photo } = data || {};
        if (Photo) {
          const image = {
            caption: '',
            thumbnailUrl: Photo.Sizes.thumbnail.Url,
            url: Photo.Sizes.normal.Url
          };
          setErrorMessage('');
          setImages(images.concat(image));
        } else {
          const { FieldErrors } = FormErrors || {};
          const { photo } = FieldErrors || {};
          const { Message } = photo || {};
          setErrorMessage(Message);
        }
        setUploading(false);
      }).catch(() => {
        setUploading(false);
        setErrorMessage('An unexpected error occurred. Please try again.');
      });
  };

  return (
    <Row
      className={rowClasses}
    >
      <Col
        className={columnClasses}
      >
        {(images.length < 6) && (
          <>
            <Row>
              <Col>
                <div>
                  <h2>Add a Photo</h2>
                  Upload up to six photos in JPG format. Must be at least 400px X 400px.
                </div>
              </Col>
            </Row>
            <div className="write-review-content__upload-image">
              <label
                className="write-review-content__upload-image__file-input"
                htmlFor="file-input"
              >
                <span className="write-review-content__upload-image__file-input__text">
                  Add Image
                </span>
                <input id="file-input" type="file" onChange={handleFileChange} data-testid="imageUpload" />
              </label>
            </div>
            {uploading
              && (
                <Row>
                  <Col>
                    <div>
                      {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
                      <img
                        className="write-review-content__upload-image__loader"
                        src="https://homedepot.ugc.bazaarvoice.com/static/1999aa/photoUploadWait.gif"
                        alt="loading"
                        title="Please wait while we upload your image."
                      />
                      <div className="write-review-content__upload-image__loader">
                        Please wait while we upload your image.
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            {errorMessage
              && (
                <Row>
                  <span className="write-review-content__upload-image__error">
                    {errorMessage}
                  </span>
                </Row>
              )}
          </>
        )}
        {images.length === 6 && (
          <Row>
            <span className="write-review-content__upload-image__exceeded-limit">
              You have added the maximum number of images.
              You must remove an image to add another.
            </span>
          </Row>
        )}
        {images.length > 0
          && (images.map((image, imageIndex) => (
            <Row key={image.thumbnailUrl || imageIndex}>
              <Col xs="1" className="write-review-content__upload-image__close__col">
                <span
                  role="presentation"
                  className="write-review-content__upload-image__close"
                  onClick={() => removeImage(imageIndex)}
                />
              </Col>
              <Col xs="2" className="write-review-content__upload-image__col">
                <Image
                  className="thumbnail"
                  src={image.thumbnailUrl}
                  alt={`uploaded-${imageIndex}`}
                />
              </Col>
              <Col xs="7">
                <div className="write-review-content__upload-image__caption">
                  <span>Add an Image Caption</span>
                  <Input
                    placeholder="What's in this photo?"
                    value={images[imageIndex].caption}
                    onChange={(caption) => updateImageCaption({ caption, imageIndex })}
                    scrollOnFocus
                  />
                </div>
              </Col>
            </Row>
          )))}
      </Col>
    </Row>
  );
};

ImageUpload.displayName = 'RatingsAndReviewsImageUpload';

ImageUpload.propTypes = {
  className: string,
  images: arrayOf(shape({})),
  setImages: func.isRequired
};

ImageUpload.defaultProps = {
  className: null,
  images: []
};
