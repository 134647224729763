import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { Input } from '@thd-olt-component-react/input';
import { invalidReviewTitleLength } from '../helper';

export const ProductReviewTitleField = ({ formInputClasses, reviewTitle, setReviewTitle }) => {
  const [errorText, setErrorText] = useState(null);
  const maximumCharacters = 50;

  useEffect(() => {
    const characterCount = reviewTitle.length;
    if (characterCount > maximumCharacters) {
      setErrorText('Your title is too long');
    } else {
      setErrorText(null);
    }
  }, [reviewTitle]);

  return (
    <Row className={formInputClasses}>
      <Col className={formInputClasses}>
        <span className="write-review-content__title">Review Title</span>
        <Input
          placeholder="The most important point"
          errorText={errorText}
          onChange={(productTitle) => setReviewTitle(productTitle)}
          value={reviewTitle}
          scrollOnFocus
          validation={(review) => invalidReviewTitleLength(review, maximumCharacters)}
        />
      </Col>
    </Row>
  );
};

ProductReviewTitleField.displayName = 'ProductReviewTitleField';

ProductReviewTitleField.propTypes = {
  formInputClasses: string,
  reviewTitle: string,
  setReviewTitle: func.isRequired
};

ProductReviewTitleField.defaultProps = {
  formInputClasses: null,
  reviewTitle: ''
};
