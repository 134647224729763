/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
export const validateEmail = (text) => {
  let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(text).toLowerCase());
};

export const validateDisplayName = (text) => {
  let stringText = String(text).toLowerCase();

  if (stringText.length < 4 || stringText.length > 24) {
    return { valid: false, message: 'Display name must be between 4 and 24 characters.' };
  }

  if (/\s/.test(stringText)) {
    return { valid: false, message: 'Display name should not contain spaces.' };
  }

  if (!/^(\d|\w){4,24}$/.test(stringText)) {
    return { valid: false, message: 'Display name should not contain special characters.' };
  }

  return { valid: true, message: '' };
};

export const invalidEmailAndDisplayName = ({ email, displayName }) => {
  const result = validateDisplayName(displayName);
  return !(result.valid && validateEmail(email));
};

export const invalidReviewTitleLength = (review, maximumCharacters) => {
  return !(review.trim().length > maximumCharacters);
};