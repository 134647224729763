import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from '@thd-olt-component-react/grid';
import CheckboxGroup from './CheckboxGroup.component';
import './Checkbox.styles.scss';

class Checkbox extends Component {
  static Group = CheckboxGroup;

  render() {
    const {
      children,
      checked,
      className,
      disabled,
      formName,
      id,
      onChange,
      value
    } = this.props;

    let optionalProps = {};

    if (typeof checked !== 'undefined') {
      optionalProps.checked = checked;
    } else {
      optionalProps.defaultChecked = checked;
    }

    return (
      <Col className={className}>
        <div className="checkbox-btn">
          <input
            className="checkbox-btn__input"
            type="checkbox"
            disabled={disabled}
            id={id}
            name={formName}
            onChange={onChange}
            value={value}
            {...optionalProps}
          />
          { /* eslint-disable-next-line */ }
          <label className="checkbox-btn__label" htmlFor={id}>
            {children}
          </label>
        </div>
      </Col>
    );
  }
}

Checkbox.propTypes = {
  /** Optional text to right of checkbox */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** HTML attribute, if true then render checkbox as checked */
  checked: PropTypes.bool,
  className: PropTypes.string,
  /** HTML attribute, if true then make checkbox grey and unclickable */
  disabled: PropTypes.bool,
  /** Groups of inputs/labels require matching name, value does not matter */
  formName: PropTypes.string,
  /** ID is required for interacting if multiple checkboxes are on page at once */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  /** Action to perform for checkbox click */
  onChange: PropTypes.func.isRequired,
  /** Form value, useful for wrapper implementation */
  value: PropTypes.string.isRequired
};

Checkbox.defaultProps = {
  children: null,
  checked: false,
  className: null,
  disabled: false,
  formName: ''
};

export default Checkbox;
