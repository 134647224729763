import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UUID } from '../utils';

class CheckboxGroup extends Component {

  generateChildren() {
    const { radio } = this.props;

    return React.Children.map(this.props.children, checkbox => {
      return React.cloneElement(checkbox, {
        radio,
        name: checkbox.props?.name || UUID()
      })
    });
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.generateChildren()}
      </div>
    );
  }
}

CheckboxGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /** Extend Styles. */
  className: PropTypes.string,
  radio: PropTypes.bool
};

CheckboxGroup.defaultProps = {
  className: null,
  radio: false
};

export default CheckboxGroup;
