import axios from 'axios';
import { reportWriteAReviewFormError } from '../../../ratings-reviews-analytics';

export function submitReview(review, productId, hostName, apiKey) {
  let formData = new FormData();
  const url = `${hostName}/ReviewServices/reviewSubmit/v1/product/${productId}?key=${apiKey}`;
  Object.keys(review).forEach((key) => {
    if (!Array.isArray(review[key]) || review[key].length === 0) {
      if (review[key] !== null) {
        formData.append(key, review[key]);
      }
    } else {
      let photo = JSON.stringify(review[key]);
      formData.append(key, ('{"photos":' + photo + '}'));
    }
  });
  // Validate to dynamic ip address check: request-ip npm
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'True-Client-IP': '1'
  };

  return axios.post(url, formData, { headers })
    .then((res) => res)
    .catch((error) => {
      if (error.response && error.response.data && error.response.data.description) {
        reportWriteAReviewFormError(error.response.data.description, 'n/a');
        return JSON.stringify(error.response.data.description);
      }
      reportWriteAReviewFormError(error.message, 'n/a');

      return (error);
    });
}
